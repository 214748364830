<template lang="pug">
.layout(:class="{'hiddenMobile': chatIsOpen}" id="layout-component")
  Snow(v-if="isNewYear")

  //client-only
  .layout__live
    Marquee
  //  .layout__banner(v-if="hasBanner" @click="goToQuests")
  //    .layout__banner-wrapper
  //      img(:src="require('~/assets/img/backgrounds/banner.svg')" class="layout__banner-icon")
  //      span.layout__banner-text {{ $t('bannerSmallText') }}
  //      <br>
  //      span.layout__banner-text.layout__banner-text--big {{ $t('bannerBigText') }}

  //.layout__live
  //  LiveWinnersOfferwall

  Header
  .layout__content
    .layout__page
      .clear__mobile
        portal-target(v-if="pageIsNotTournaments" name="breadcrumbs")
      Nuxt

    Footer

  MobileMenu.clear__mobile

  transition(name='modal-fade-scale', duration='200')
    .layout__modal-container(v-if='isModalActive')
      .layout__overlay(v-if='isModalActive', @click='onOverlayClick')
      Modal(v-show='isModalVisible')

  client-only
    //CookiePolicy
    NextTournament(v-if='user')

  portal-target(name='tooltip', multiple)

  div.layout__link.layout__support-button(v-if="isAuthorized" ref="toggler" @click.stop="switchChat")
      .layout__link-body
        .layout__link-icon(:title="supportButtonText")
          img(:src="require('~/assets/img/icons/chat.svg')" :alt="$t('userSupportCenter')" alt="support")
        span.layout__link-text {{ $t('chat') }}
      div(v-if="showRedPoint" :class="{'--with-point': true}")
        | {{ newMessages.length }}
  div.layout__link.layout__support-button(v-else ref="toggler" v-support-button)
      .layout__link-body
        .layout__link-icon(:title="supportButtonText")
          img(:src="require('~/assets/img/icons/chat.svg')" :alt="$t('userSupportCenter')" alt="support")
        span.layout__link-text {{ $t('chat') }}
      div(v-if="showRedPoint" :class="{'--with-point': true}")
        | {{ newMessages.length }}

  transition(name="slide-down")
      .layout__menu(v-if="isMenuVisible" ref="menu")
        .layout__menu-list
          .layout__menu-list-item(v-support-button @click="toggleMenu")
            img.layout__menu-list-item-icon(:src="require('~/assets/img/icons/question-mark-white.svg')" alt="support")
            | {{ $t('askSupport') }}
          .layout__menu-list-item(v-if="isAuthorized" @click="switchChat")
            img.layout__menu-list-item-icon(:src="require('~/assets/img/icons/chat.svg')" alt="chat")
            | {{ $t('chat') }}
            div(v-if="showRedPoint" :class="{'--with-point': true}")
              | {{ newMessages.length }}
  client-only(v-if="isAuthorized && chatIsOpen")
    .layout__chat-bg
    ChatApp(@close="switchChat" :key="routeName")
    //.layout__chat-btn(v-else @click="switchChat")
    //  img.layout__chat-btn-angle(:src="require('~/assets/img/icons/angle-left.svg')")
    //  img.layout__chat-btn-icon(:src="require('~/assets/img/icons/chatvvv.svg')")
    //  .layout__chat-btn-text(v-t="'chat'")
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getMessaging, onMessage, getToken } from 'firebase/messaging'
import Duel from '~/mixins/Duel'
import Header from '~/components/Header/Header'
import Footer from '~/components/Footer/Footer'
import Modal from '~/components/Modal/Modal'
import CookiePolicy from '~/components/CookiePolicy/CookiePolicy'
import MobileMenu from '~/components/MobileMenu/MobileMenu'
import NextTournament from '~/components/NextTournament/NextTournament'
// import Snow from '~/components/NewYearLayout/NewYearLayout'
import OfferwallService from '~/services/OfferwallService'
import UtmService from '~/services/UtmService'
import UsersService from '~/services/UsersService'
import ChatApp from '~/components/ChatApp/ChatApp'
import Marquee from '~/components/MarketplaceComponents/Marquee'
// import { messaging } from '~/plugins/firebase.client.js'

export default {
  name: 'LayoutDefault',

  components: {
    Marquee,
    ChatApp,
    NextTournament,
    CookiePolicy,
    Header,
    Footer,
    Modal,
    MobileMenu
    // Snow
  },

  fetch () {
    this.checkToken()
  },

  data () {
    return {
      isLiveStatsVisible: false,
      isPWAOpenerVisible: true,
      routesWithBanner: [
        'index',
        'tournaments',
        'store-tab',
        'game-tournaments',
        'game-tournaments-id'
      ],
      isMenuVisible: false,
      pagesNotAutoAuthWithRefLink: ['missions'],
      newMessagesInterval: null
    }
  },

  computed: {
    ...mapState('modal', {
      isModalActive: 'isActive',
      isModalVisible: 'isVisible',
      modalComponent: 'component',
      canClose: 'canClose'
    }),
    ...mapState('user', ['token', 'user', 'verification']),
    ...mapState('games', ['gamesByName', 'gamesById']),
    ...mapState('chat', ['chatId', 'newMessages']),
    ...mapGetters('events', ['isNewYear']),
    ...mapGetters('user', ['isAuthorized', 'isAdmin']),

    supportButtonText () {
      return this.$t('help')
    },

    routeName () {
      return this.$route.name
    },

    canShowLiveWinners () {
      return (
        this.$route.params.game && this.gamesByName[this.$route.params.game]
      )
    },

    pageIsNotTournaments () {
      return !this.$route.name?.includes('tournaments')
    },

    hasBanner () {
      return this.$i18n.locale === 'ru' &&
        this.routesWithBanner.includes(this.$route.name.split('__')[0])
    },

    isPWA () {
      return this.$route.query.standalone
    },

    notPWA () {
      return !this.isPWA
    },

    isMainPage () {
      return ['index'].includes(this.$route.name?.split('___')[0])
    },

    chatIsOpen () {
      return !!this.chatId
    },

    showRedPoint () {
      return this.newMessages?.length
    }
  },

  watch: {
    $route: {
      handler () {
        if (process.server) {
          return
        }

        this.$socket.emit('platform:online', {
          route: this.$route.path.replace('/', ''),
          token: this.token || 'not auth'
        })

        try {
          window?.ym?.(96913300, 'hit', this.$route.path)
        } catch (e) {
          console.log(e)
        }

        const element = window.document.getElementById('layout-component')
        element?.scrollTo({ top: 0, left: 0 })
      },

      immediate: true
    }
  },

  beforeMount () {
    if (!sessionStorage.getItem('is-live-state-hidden')) {
      this.isLiveStatsVisible = true
    }

    this.checkToken()

    this.$i18n.onLanguageSwitched = (oldLocale, newLocale) => {
      if (this.$cookiz.get('i18n_locale') !== newLocale) {
        this.$cookiz.set('i18n_locale', newLocale)
      }
    }
  },

  mounted () {
    this.checkKeitaroId()
    this.checkKochavaDeviceId()
    this.checkFbPixel()

    this.getChatData()

    this.newMessagesInterval = setInterval(this.getChatData, 30000)

    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      console.log('add google messaging')
      const messaging = getMessaging()

      onMessage(messaging, (payload) => {
        console.log('Message on client', payload)
      })

      console.log('google auth anon')
      this.firebaseAuth()
      console.log('google auth anon ok')
    }

    if (this.isPWA) {
      OfferwallService.pwaTaskRequest(this.$axios)
    }

    this.trackRefFollow()
    try {
      this.checkRatingChanged()
    } catch (e) {
      console.log(e)
    }
    // this.checkHolidayModal()
    this.reloadPWAOnCodeUpdate()
    setTimeout(this.hidePWAOpener, 8000)

    this.$socket.on('user:update', this.onUserUpdate)
    this.$socket.on('user:duel:update', this.onUserDuelUpdate)
    this.$socket.on('platform:online', this.setOnline)

    if (window.location.search?.includes('utm_source')) {
      const queryString = window.location.search
      const queryParams = new URLSearchParams(queryString)

      window.sessionStorage.setItem('utmLabels', queryString.replace('?', ''))

      const utmSource = queryParams.get('utm_source')
      const utmMedium = queryParams.get('utm_medium')
      const utmCampaign = queryParams.get('utm_campaign')
      const utmTerm = queryParams.get('utm_term')
      const utmContent = queryParams.get('utm_content')

      this.$cookiz.set('utm_source', utmSource)
      this.$cookiz.set('utm_medium', utmMedium)
      this.$cookiz.set('utm_campaign', utmCampaign)
      this.$cookiz.set('utm_term', utmTerm)
      this.$cookiz.set('utm_content', utmContent)
    }

    try {
      UtmService.sendUtm(this.$axios, {
        utm_source: this.$cookiz.get('utm_source'),
        utm_medium: this.$cookiz.get('utm_medium'),
        utm_campaign: this.$cookiz.get('utm_campaign'),
        utm_term: this.$cookiz.get('utm_term'),
        utm_content: this.$cookiz.get('utm_content'),
        web_id: JSON.stringify(this.$cookiz.get('gret')),
        ga: JSON.stringify(this.$cookiz.get('_ga'))
      })
    } catch (e) {
      console.log(e)
    }

    if (
      window.Notification?.permission &&
      Notification.permission !== 'granted' &&
      this.isAuthorized
    ) {
      Notification.requestPermission()
    }

    window.jivo_onLoadCallback = () => {
      if (this.user) {
        window.jivo_api.setContactInfo({
          name: this.user.full_name,
          email: this.user.email,
          phone: this.verification?.phone || '',
          description: `${this.$config.WWW_HOST}/user/${this.user.alias}`
        })

        window.jivo_api.setCustomData([
          {
            title: 'profile',
            content: this.user.id,
            link: `${this.$config.WWW_HOST}/user/${this.user.alias}`
          }
        ])
      }
    }

    if (this.isAuthorized) {
      this.fetchOrders()

      UsersService.getPollsList(this.$axios).then((response) => {
        const poll = response?.data?.data?.[0]

        if (poll) {
          this.showModal({
            component: 'ModalQuestionnaire',
            data: {
              poll
            }
          })
        }
      })

      if (this.$route.query.open_chat === '1') {
        // OPEN CHAT
        this.setChatId('ALL')
        this.setChatName('chat')
      }
    }
  },

  methods: {
    ...mapMutations('modal', { toggleModal: 'toggle' }),
    ...mapMutations('user', ['setUserData', 'setOnline']),
    ...mapMutations('chat', ['setChatId', 'setChatName']),
    ...mapActions('chat', ['fetchNewMessages']),
    ...mapActions('modal', ['showModal']),
    ...mapActions('duels', ['setNotifiedDuel']),
    ...mapActions('user', ['fetchOrders']),

    async firebaseAuth () {
      try {
        const auth = getAuth()
        await signInAnonymously(auth)
        this.activate()
      } catch (e) {
        console.log(e)
      }
    },

    switchChat () {
      this.setChatId('ALL')
      this.setChatName('chat')
      // this.$gtag.event('chat_click', { account_id: this.user?.id, place: 'bottom_button' })
    },

    getChatData () {
      if (this.user?.id) {
        this.fetchNewMessages(this.user.id)
      }
    },

    async activate () {
      const { messaging } = require('~/plugins/firebase.client.js')

      let token

      try {
        if (!window.Notification) {
          window.Notification = {
            permission: 'granted'
          }
        }

        token = await getToken(messaging, {
          vapidKey: 'BPHJfDmD7pxz0x9MRRADx1OEtZaY-Jiuzbt50VVL3w8SRqyqaQFS0AIp0JwFDQ0wDmT6hPkR5QpdCMORb4gh6vc'
        })

        if (token) {
          this.$axios.post('/user/account/push-notification/google-messaging', { deviceId: token })
        }
      } catch (e) {
        console.log('catch:', e)
      }
    },

    checkKeitaroId () {
      if (this.$route.query.external_id) {
        window.localStorage.setItem('keitaroId', this.$route.query.external_id)
        this.$cookiz.set('keitaroId', this.$route.query.external_id)
      }
      if (this.$route.query.sub1) {
        window.localStorage.setItem('marketingSub1', this.$route.query.sub1)
        this.$cookiz.set('marketingSub1', this.$route.query.sub1)
      }
    },

    checkKochavaDeviceId () {
      const currentKID = this.$cookiz.get('kochava_device_id')
      const kFirst = !window.localStorage.getItem('kochavaFirst')

      if (currentKID && kFirst) {
        window.localStorage.setItem('kochavaFirst', '1')
        // this.$router.push(this.localeLocation('/freedom'))
      }
    },

    checkFbPixel () {
      if (this.$route.query.pixel) {
        window.localStorage.setItem('pixel', this.$route.query.pixel)
        this.$cookiz.set('pixel', this.$route.query.pixel)
      }
      if (this.$route.query.fbclid) {
        window.localStorage.setItem('fbclid', this.$route.query.fbclid)
        this.$cookiz.set('fbclid', this.$route.query.fbclid)
      }
      if (this.$route.query.click_id) {
        window.localStorage.setItem('adgateclid', this.$route.query.click_id)
        this.$cookiz.set('adgateclid', this.$route.query.click_id)
      }
    },

    toggleMenu () {
      this.isMenuVisible = !this.isMenuVisible
    },

    async checkRatingChanged () {
      // const lastCompletedTournament = (await TournamentsService.getMyCompletedTournaments(this.$axios))?.data?.data?.[0]
      // const lastCompletedTournamentId = this.$cookiz.get('lastCompletedTournament')
      // const gameName = this.gamesById[lastCompletedTournament?.game_id]?.name
      // const username = this.user?.game_accounts?.find(a => a.game === gameName)?.id

      // if (lastCompletedTournament && lastCompletedTournament.id !== lastCompletedTournamentId && !lastCompletedTournament.extra_data?.state?.game?.winners?.map(w => w.platform_username).includes(username)) {
      //  this.showModal({
      //    component: 'ModalRatingChanged',
      //    data: {
      //      tournamentId: lastCompletedTournament.id,
      //      game: gameName
      //    }
      //  })
      // }
    },

    goToQuests () {
      this.$router.push(this.localeLocation('/missions'))
    },

    /**
     * В pwa токен из кукисов не читается видимо у serviceWorker нет к ним доступа,
     * но на клиенте куки доступны норм, поэтому тут подгружаются юзер на клиенте в таком случае
     */
    checkToken () {
      const cookieToken = this.$cookiz.get('auth_token')

      if (cookieToken !== this.$store.state.user.token) {
        this.$store.commit('user/setToken', cookieToken)
        this.$store.commit('user/setUserData', null)

        if (cookieToken) {
          this.$store.dispatch('user/fetchUser')
        }
      }
    },

    hidePWAOpener () {
      this.isPWAOpenerVisible = false
    },

    async reloadPWAOnCodeUpdate () {
      // reload page when new version of code is available
      const workbox = await window.$workbox

      if (workbox) {
        workbox?.addEventListener('installed', (event) => {
          if (event?.isUpdate) {
            location.reload()
          }
        })
      }
    },

    showLogin () {
      const [hash] = this.$route.hash.split('?')

      if (!this.isAuthorized && (!hash || hash === '')) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'register'
          }
        })
      }
    },

    showGamesToLinkAccount () {
      if (this.user && !this.user?.game_accounts?.length) {
        this.showModal({
          component: 'ModalChooseGameToLinkAccount'
        })
      }
    },

    async trackRefFollow () {
      if (this.$route.query.ref) {
        await this.$axios.post(`/referrals/${this.$route.query.ref}/track`)
        this.$cookiz.set('ref', this.$route.query.ref)
        window.localStorage.setItem('ref', this.$route.query.ref)

        setTimeout(() => {
          this.$router.replace(this.$route.path)
        }, 500)

        if (!this.user && !this.pagesNotAutoAuthWithRefLink.includes(this.$route.name.split('___')[0])) {
          this.showModal({
            component: 'Auth',
            data: {
              mode: 'register'
            }
          })
        }
      }
    },

    onUserUpdate (event) {
      this.setUserData(event)
      this.$store.dispatch('user/fetchProducts')
      // this.$store.dispatch('user/fetchFreerolStatus')
    },

    onUserDuelUpdate ({ duel }) {
      Duel.methods.notifyDuelPlayers.call(this, duel)
    },

    onOverlayClick () {
      if (this.canClose) {
        this.toggleModal()
      }
    },

    checkHolidayModal () {
      if (this.isNewYear) {
        const isModalShowed = this.$cookiz.get('newYearModal2022') === 'showed'

        if (!isModalShowed) {
          this.$cookiz.set('newYearModal2022', 'showed')

          this.showModal({ component: 'NewYearModal' })
        }
      }
    }
  },

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.WWW_HOST + this.$route.path
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/reset';
@import '../assets/styles/common';
@import '../assets/styles/fonts';

.hiddenMobile {
  overflow-y: hidden !important;

  @media (min-width: $breakpointDesktopMedium) {
    overflow-y: auto !important;
  }
}

.layout {
  @include silent-scroll;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;

  @media (min-width: $breakpointTablet) {
    @include scroll;
    overflow-x: hidden;
  }

  &__chat-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 200vh;
    overflow: hidden;
    z-index: 997;

    @media (min-width: $breakpointDesktop) {
      display: none;
    }
  }

  &__add-to-home-screen {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 2000;
    background: $primary60;
    color: $white;
    bottom: 62px;
    padding: 10px 20px;
    padding-right: 40px;
    width: 100%;
    opacity: 1;
    transition: all 2s;

    &--hidden {
      opacity: 0;
      bottom: -150px;
    }

    span {
      line-height: 20px;
    }

    img {
      display: inline-block;
      height: 18px;
      margin: 0 8px;
      position: relative;
    }
  }

  &__banner {
    min-height: 64px;
    padding: 12px 14px;
    font-size: 10px;
    color: #F4F8FA;
    font-weight: 700;
    background-color: #FFE20B;
    background-image: url(~@/assets/img/backgrounds/banner-small.svg);
    background-repeat: no-repeat;
    background-position: center right;
    overflow: hidden;
    cursor: pointer;

    @media (min-width: $breakpointDesktop) {
      min-height: 120px;
      font-size: 16px;
      background-image: none;
    }

    &-wrapper {
      position: relative;
      max-width: 1250px;
      margin: auto;
    }

    &-icon {
      display: none;

      @media (min-width: $breakpointDesktop) {
        position: absolute;
        display: block;
        top: -12px;
        right: 0;
        height: 120px;
        max-width: 700px;
      }

      @media (min-width: $breakpointDesktopMedium) {
        max-width: 1000px;
      }
    }

    &-text {
      display: inline-block;
      padding: 2px 4px;
      background-color: rgba(234, 115, 29, .5);
      border-radius: 2px;

      @media (min-width: $breakpointDesktop) {
        padding: 6px 12px;
      }

      &--big {
        margin-top: 2px;
        font-size: 18px;

        @media (min-width: $breakpointDesktop) {
          font-size: 28px;
        }
      }
    }
  }

  &__live {
    position: relative;
    z-index: 3;
    height: 48px;
  }

  &__content {
    padding-top: 0;
    max-width: 100vw;
  }

  &__page {
    max-width: calc(100% - 90px);
    min-height: calc(100vh - 510px);
    width: 100%;
    transition: 0.2s $easing;
    position: relative;
    // margin-left: 80px;
    margin: auto;

    @media (min-width: $breakpointDesktopMedium) {
      max-width: calc(100% - 160px);
      // margin-left: 140px;
    }

    @media (min-width: 1650px) {
      max-width: calc(1500px - 140px);
      margin: auto;
    }
  }

  &__overlay {
    background-color: rgb(0 0 0 / 66%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    cursor: pointer;
  }

  &__modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 998;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .fade-scale-enter-active,
  .fade-scale-leave-active {
    transition: all 0.2s $easing;
  }

  .fade-scale-enter,
  .fade-scale-leave-to {
    opacity: 0;
    transform: scale(0.75, 0.75);
  }

  .modal-fade-scale-enter-active .modal,
  .modal-fade-scale-leave-active .modal {
    transition: all 0.2s $easing;
  }

  .modal-fade-scale-enter .modal,
  .modal-fade-scale-leave-to .modal {
    opacity: 0;
    transform: scale(0.75, 0.75);
  }

  .button-table {
    display: none;
    margin-top: 32px;
  }

  @media (max-width: $breakpointHeaderMobile) {
    &__page {
      max-width: 100%;
      margin-left: 0;
    }

    &__content {
      padding-bottom: 48px;
    }
  }

  &__link {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 8px;
    transition: 0.3s $easing;
    background: $blue-grey80;
    position: relative;
    cursor: pointer;

    &-text {
      margin-left: 4px;
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &-icon {
      width: 24px;
      height: auto;
      min-width: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg,
      path,
      rect {
        transition: 0.3s $easing;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .--with-point {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background: $white;
      height: 24px;
      min-width: 24px;
      top: -8px;
      right: -8px;
      border-radius: 50%;
      color: $indigo60;
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
    }

    &-name {
      @include heading7;

      color: $blue-grey10;
      margin-left: 12px;

      //min-width: 132px;
      //width: 132px;
      transition: 0.3s $easing;
      opacity: 0;
    }

    &-btn-icon {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: flex-start;

      svg,
      path,
      rect {
        transition: 0.3s $easing;
      }
    }

    &-body {
      display: flex;
      align-items: center;
      height: 48px;
      transition: 0.3s $easing;
      border-radius: 8px;
      padding: 0 0 0 12px;
      width: 100%;
    }

    &-btn {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      margin-right: 8px;
      transition: 0.3s $easing;
      cursor: pointer;
      position: absolute;
      border-radius: 8px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      z-index: -1;
    }

    &.layout__support-button {
      display: none;

      @media (min-width: $breakpointTablet) {
        display: block;
        position: fixed;
        top: 120px;
        right: 76px;
        width: 92px;
        z-index: 2;
        background: #3949ab;
      }

      &.--highlight {
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: -4px;
          top: -4px;
          right: -4px;
          bottom: -4px;
          pointer-events: none;
          border: 2px solid #3949ab;
          border-radius: 8px;
        }
      }

      img {
        path:first-child {
          fill: #fff;
          opacity: 1;
        }

        path:last-child {
          fill: #3949ab;
          opacity: 1;
        }
      }
    }
  }

  &__menu {
    background: $blue-grey70;
    padding: 16px 20px 20px;
    border: 1px solid $blue-grey40;
    position: fixed;
    bottom: 115px;
    right: 16px;
    border-radius: 8px;
    color: #fff;
    width: 240px;
    z-index: 11;

    .social-links__social-item,
    &-list-item {
      @include hint;

      position: relative;
      margin-bottom: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 12px;

        path {
          fill: #9bafca;
          transition: all 0.2s $easing;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        img {
          path {
            fill: #fff;
          }
        }
      }

      .--with-point {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: $primary60;
        height: 24px;
        min-width: 24px;
        top: 0;
        right: 0;
        border-radius: 50%;
        color: $white;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
      }
    }

    &-list {
      &-title {
        @include caption;

        color: $blue-grey10;
        margin-bottom: 12px;
      }
    }
  }

  &__chat-btn {
    display: grid;
    grid-template-columns: 11px 24px max-content;
    grid-gap: 4px;
    align-items: center;
    padding: 10px;
    background: $blue-grey70;
    position: fixed;
    top: 0;
    right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 49;
    cursor: pointer;

    &-text {
      color: $white;
    }

    &-angle {
      width: 24px;
    }

    &-icon {
      width: 24px;
    }
  }
}

.clear {
  &__mobile {
    @media (min-width: $breakpointTablet) {
      display: none;
    }
  }
}
</style>
