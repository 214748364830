import StoreService from '@/services/StoreService'
import CurrencyService from '@/services/CurrencyService'

export const state = () => ({
  apps: null,
  counters: [],
  currencies: [],
  orderContent: undefined,
  orderFields: undefined,
  orderTextFields: undefined,
  marketplaceTabId: 'game'
})

export const mutations = {
  setApps (state, apps) {
    state.apps = apps.sort((a, b) => a.title.localeCompare(b.title))
  },

  setCounters (state, counters) {
    state.counters = counters
  },

  setCurrencies (state, currencies) {
    state.currencies = currencies
  },

  setOrderContent (state, content) {
    state.orderContent = content
  },

  setOrderFields (state, fields) {
    state.orderFields = fields
  },

  setOrderTextFields (state, fields) {
    state.orderTextFields = fields
  },

  setMarketplaceTabId (state, tabId) {
    state.marketplaceTabId = tabId
  }
}

export const actions = {
  async fetchApps ({ commit }) {
    try {
      const { data } = await StoreService.getMarketplaceApps(this.$axios)

      commit('setApps', data.data)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: app loading error ::`, e)
    }
  },

  async fetchCounters ({ commit }) {
    try {
      const { data } = await StoreService.getMarketplaceCounters(this.$axios)

      commit('setCounters', data.data)
    } catch (e) {
      console.error(`${new Date().toUTCString()} :: fetch counters error ::`, e)
    }
  },

  async fetchCurrencies ({ commit }) {
    const data = await CurrencyService.getAllCurrencies(this.$axios)

    commit('setCurrencies', data)
  }
}
