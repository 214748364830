class StoreService {
  constructor () {
    if (StoreService.instance) {
      return StoreService.instance
    }
  }

  updateMarketplaceCategorySeoData (engine, categoryId, lang, seoData) {
    return engine.post(`/marketplace/category/${categoryId}/seo`, { lang, seoData })
  }

  getPageSeo (engine, lang, url) {
    return engine.get('/admin/seo-url', { params: { lang, url } })
  }
}

export default new StoreService()
